<template>
  <div>
    <h1>Heritage Reference</h1>
    <form @submit.prevent="getHeritageUrl">
      <label
        >Reference Number
        <input ref="refNo" type="text" v-model.trim="referenceNumber"
      /></label>
      <input type="submit" value="Search" />
      <button @click="clearAllSearchTerms">Clear all</button>
    </form>
    <section role="alert" aria-atomic="true">
      {{ status }}
    </section>
    <section>
      <a :href="outputUrl" ref="url">{{ outputSongNameAndArtist }}</a>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

function removeItemAll (arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

export default {
  data () {
    return {
      referenceNumber: "",
      outputUrl: "",
      outputSongNameAndArtist: "",
      status: "",
    };
  },
  mounted () {
    this.$refs.refNo.focus();
  },
  methods: {
    clearAllSearchTerms () {
      this.referenceNumber = "";
      this.outputUrl = "";
      this.outputSongNameAndArtist = "";
      this.status = "";
    },
    async getHeritageUrl () {
      this.outputUrl = "";
      this.outputSongNameAndArtist = "";
      this.status = "";
      let ref = this.cleanUpReferenceNumber(this.referenceNumber);

      if (!this.referenceNumber) {
        this.outputUrl = `https://heritagequay.org/archives/reference/`;
      } else {
        ref = ref.replace(/\//g, '-');

        const url = `https://plxbahnb4h.execute-api.ap-southeast-2.amazonaws.com/heritagesearch/${ref}`;
        // const url = `http://localhost:5000/heritagesearch/${ref}`;

        axios.defaults.crossDomain = true;

        try {
          let result = await axios({
            method: 'get',
            url
          })

          this.outputUrl = result.data.url;
          this.outputSongNameAndArtist = result.data.songNameAndArtist;
          if (this.outputUrl === "") {
            this.status = "Could not find a download link for" + result.data.songNameAndArtist
          } else {
            this.$nextTick(() => {
              this.$refs.url.focus();
            })
          }
        } catch (e) {
          this.status = "Could not find a download link."
        }

      }
    },
    cleanUpReferenceNumber (ref) {
      ref = ref.split("/")
      ref = removeItemAll(ref, "");
      let output = ""
      for (let i = 0; i < ref.length; i++) {
        output += ref[i] + "/"
      }
      return output.substring(0, output.length - 1)
    },
  },
}
</script>
